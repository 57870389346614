.about {
    padding: 50px 0;
    background-color: #f8f9fa;
    }
    
    .about h1 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 50px;
    }
    
    .about h2 {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 30px;
    }
    
    .about p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    }
    
    .about ul {
    margin-bottom: 30px;
    }
    
    .about li {
    font-size: 1.2rem;
    line-height: 1.6;
    }
    
    .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
    }