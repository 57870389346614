/* General styles */

h3 {
  font-weight: bold;
  font-size: 2rem;
}

p {
  margin: 0;
}

.btn {
  font-weight: bold;
}

a {
  color: inherit;
}

/* Header styles */

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.video-background .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}

.video-background h3 {
  font-size: 3rem;
}

.video-background span {
  font-size: 1.5rem;
}

/* Services styles */

.services {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.services h3 {
  margin-bottom: 30px;
  text-align: center;
}

.services table {
  margin: 0 auto;
}

.services td {
  text-align: center;
}

.services img {
  height: 80px;
  width: 80px;
}

.services p {
  margin: 10px 0;
  font-weight: bold;
  font-size: 1.2rem;
}

/* Expertise styles */

.expertise {
  padding: 50px 0;
}

.expertise h3 {
  margin-bottom: 30px;
  text-align: center;
}

.expertise table {
  margin: 0 auto;
}

.expertise td {
  text-align: center;
}

.expertise svg {
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
}

/* Contact styles */

#contact {
  background-color: #1b1b1b;
  color: #fff;
  padding: 50px;
}

#contact h3 {
  margin-bottom: 30px;
  text-align: center;
}

#contact form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

#contact form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

#contact form input,
#contact form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
}

#contact form textarea {
  height: 150px;
}

#contact form button[type="submit"] {
  background-color: #f8f9fa;
  color: #1b1b1b;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#contact form button[type="submit"]:hover {
  background-color: #dee2e6;
}

@media (max-width: 768px) {
  #contact form {
    grid-template-columns: 1fr;
  }
}
