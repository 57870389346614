#services {
    background-color: #f8f9fa;
    padding: 50px 0;
  }
  
  #services h2 {
    color: #1b1b1b;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .services-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service {
    width: 23%;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .service img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .service h3 {
    color: #1b1b1b;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .cta {
    margin-top: 50px;
    text-align: center;
  }
  
  .cta button {
    background-color: #1b1b1b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .cta button:hover {
    background-color: #fff;
    color: #1b1b1b;
    border: 1px solid #1b1b1b;
  }
  
  @media (max-width: 992px) {
    .service {
      width: 31%;
    }
  }
  
  @media (max-width: 768px) {
    .service {
      width: 48%;
    }
  }
  
  @media (max-width: 576px) {
    .service {
      width: 100%;
    }
  }
  