.footer-clean {
  background-color: #f9f9f9;
  color: #333333;
  padding: 50px 0;
}

.footer-clean h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
}

.footer-clean ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.footer-clean ul li {
  margin-bottom: 12px;
}

.footer-clean ul a {
  color: #333333;
  font-size: 14px;
  opacity: 0.8;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
}

.footer-clean ul a:hover {
  opacity: 1;
}

.footer-clean .item.social {
  text-align: right;
}

.footer-clean .item.social > a {
  border: 1px solid #cccccc;
  border-radius: 50%;
  color: inherit;
  display: inline-block;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
  margin-top: 22px;
  opacity: 0.75;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  width: 40px;
}

.footer-clean .item.social > a:hover {
  opacity: 0.9;
}

.footer-clean .item.social > a:first-child {
  margin-left: 0;
}

@media (max-width: 991px) {
  .footer-clean .item.social > a {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer-clean .item {
    padding-bottom: 20px;
    text-align: center;
  }

  .footer-clean .item.social {
    text-align: center;
  }

  .footer-clean .item.social > a {
    margin-top: 10px;
  }
}

.footer-clean .copyright {
  font-size: 14px;
  margin-top: 24px;
  opacity: 0.6;
  text-align: center;
}
